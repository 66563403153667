<template>
    <section>
        <span class="d-flex justify-content-between">
            <h3>Liste des réservations</h3>

            <span class="d-flex align-self-center justify-content-enter align-items-center">
                <!-- nombre d'éléments à afficher -->
                <b-dropdown  variant="outline-primary" toggle-class="text-decoration-none" no-caret size="sm" class="m-2">
                    <template #button-content>
                        {{ perPage }} <i class="mdi mdi-chevron-down"></i>
                    </template>
                    <b-dropdown-item href="#" @click="perPage=10">10</b-dropdown-item>
                    <b-dropdown-item href="#" @click="perPage=25">25</b-dropdown-item>
                    <b-dropdown-item href="#" @click="perPage=50">50</b-dropdown-item>
                </b-dropdown>
                <!-- filtre des status -->
                <b-dropdown  variant="outline-primary" toggle-class="text-decoration-none" no-caret size="sm" class="m-2">
                    <template #button-content>
                        <i class="mdi mdi-filter-variant"></i>
                    </template>
                    <b-dropdown-item @click="selected_status='all'" >Tout</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="#" @click="selected_status='coming'">A venir</b-dropdown-item>
                    <b-dropdown-item href="#" @click="selected_status='ended'">Terminé</b-dropdown-item>
                    <b-dropdown-item href="#" @click="selected_status='cancelled'">Annulé</b-dropdown-item>
                </b-dropdown>
                <b-input-group>
                    <b-form-input placeholder="Recherche" v-model="search"></b-form-input>
                </b-input-group>
            </span>
                
        </span>
        <div class="col-12">
            <div v-if="isload" class="jumping-dots-loader">
                <span></span>
                <span></span>
                <span></span>
            </div>

        <!-- tableau et contenu -->
        <div v-else>
            
            <b-table 
            :items="filtered_reservations" 
            id="table-list" 
            responsive :per-page="perPage" 
            :current-page="currentPage" 
            :fields="fields" 
            :sort-by.sync="sortBy" 
            :sort-desc.sync="sortDesc"
            :filter="search"
            @row-clicked="details"
            :sticky-header="stickyHeader"
            no-border-collapse
            >

                <template #cell(name)="row">
                    <span class="pointer" v-if="row.item.on_behalf_of.name">{{ row.item.on_behalf_of.name  }} </span>
                    <span v-else> information non fournie </span>
                </template>

                <template #cell(appointments)="row">
                <span v-if="row.item.appointments">
                    <span> {{ row.item.appointments[0].service.name }} </span>
                </span>
                </template>

                <template #cell(date_start)="row">
                    <span v-if="row.item.appointments">{{formatDate2(row.item.appointments[0].start_hour)}}</span>
                </template>

                <template #cell(status)="row">
                    <span v-if="row.item.status !=='cancelled'">
                        <span class="badge badge-primary" v-if="new Date(row.item.appointments[0].start_hour) > Date.now()">à Venir</span>
                        <span class="badge badge-success" v-else>Terminé</span>
                    </span>
                    <span v-else>
                        <span class="badge badge-danger">Annulé</span>
                    </span>
                </template>
            </b-table>
             <!-- message si tableau vide -->
            <h4 v-if="filtered_reservations.length<1 && isload==false"> Pas de réservation</h4>
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="table-list"
                align="right">
            </b-pagination>
        </div>
        
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import { dateMixin } from '../mixins/date'

export default {
    name:'listOfReservations',
    mixins:[dateMixin],
    data(){
        return{
            reservations:[],
            filtered_reservations:[],
            isload:true,
            selected_status:'all',
            // tableau
            perPage: 10,
            sortBy: 'name',
            currentPage: 1,
            sortDesc: false,
            sortByFormatted: true,
            filterByFormatted: true,
            sortable: true,
            search:'',
            // scroll dans le tableau
            stickyHeader: false,
            fields: [
                { key: 'name', stickyColumn: true, label:'Nom du Client', sortable: true },
                { key: 'appointments', label:'Prestations', sortable: true },
                { key: 'date_start', label:'pour le',sortable: true },
                { key: 'status', sortable: true }
            ],
        }
    },
    computed:{
        rows() {
            return this.reservations.length
        }
    },
    watch:{
        async selected_status(value){
            if(value ==='all'){
                this.filtered_reservations = await this.reservations
            }
            else if(value ==='coming') {
                this.filtered_reservations = this.reservations.filter(reservation=>
                   new Date(reservation.appointments[0].start_hour) > Date.now() && reservation.status !=='cancelled'
                )
                console.log('filtré', this.filtered_reservations);
            }
            else if(value ==='ended') {
                this.filtered_reservations = this.reservations.filter(reservation=>
                   new Date (reservation.appointments[0].start_hour) < Date.now() && reservation.status !=='cancelled'
                )
            }
            else if(value ==='cancelled') {
                this.filtered_reservations = this.reservations.filter(reservation=>
                   reservation.status =='cancelled'
                )
                // console.log(this.filtered_reservations);
            }
        }
    },
    methods:{
        getReservations(){
            axios.get(`reservations`)
            .then(resGetReservations =>{
                this.reservations = resGetReservations.data.data
                this.filtered_reservations = this.reservations
                this.isload = false
            })
            .catch(errGetReservations =>{
                console.log({errGetReservations});
            })
        },
        details(record){
            this.$router.push({
                name:"reservationDetails",
                params:{
                    ulid:record.ulid
                }
            })
        },
    },
    mounted(){
        this.getReservations()
    }
}
</script>