export let dateMixin = {
    name:"dateMixin",
    methods:{
        // traduction EN en FR
        // Thu Apr 14 2022 09:30:00 GMT+0300 (heure normale d’Afrique de l’Est) en
        // date = date longue
        fullDateFr(date){
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            date = date.toLocaleDateString('fr-FR', options)
            return date
        },
        // convertir format isoString en YYYY-MM-DD HH:MM
        formatDate(dateString){
            // prendre le décalage horaire
              let utc = dateString.charAt(21)
              let date = new Date(dateString)
              let originalUTC = date.toISOString().split('T')[1].slice(0,5)
              let getHour = parseInt(originalUTC.split(':')[0])
              let displayedHour = getHour + parseInt(utc)
              date = date.toISOString().split('T')[0]+' '+ displayedHour.toString()+date.toISOString().split('T')[1].slice(2,5)
              return date
        },
        // date String en DD-MM-YYYY
        formatDate2(date){
            // récupérer le jour, mois et année
            let date1= date.substring(0,11)
            // diviser par -
            let date2 = date1.split('-')
            // reformater
            let date3 = date2[2]+'-'+date2[1]+'-'+date2[0]
            //supprimer le T de la date
            let date4 = date3.replace('T','')
            return date4
        },
        // date String en YYYY-MM-DD
        formatDate3(date){
            // récupérer le jour, mois et année
            let date1= date.substring(0,11)
            // diviser par -
            let date2 = date1.split('-')
            // reformater
            let date3 = date2[0]+'-'+date2[1]+'-'+date2[2]
            //supprimer le T de la date
            let date4 = date3.replace('T','')
            return date4
        },
        // date 2023-06-07T05:40:00.000Z en 2023-06-07T05:40 -- date sans timezone
        formatDate4(date){
            const dateObj = new Date(date);
            const year = dateObj.getFullYear();
            const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
            const day = dateObj.getDate().toString().padStart(2, "0");
            const hours = dateObj.getHours().toString().padStart(2, "0");
            const minutes = dateObj.getMinutes().toString().padStart(2, "0");
            return `${year}-${month}-${day}T${hours}:${minutes}`
        },
        //type date en DD-MM-YYYY
        typeDateToDDMMYYYY(date){
            return date.getDay() + "-" + date.getMonth() + "-" + date.getYear()
        },
        
        // récupérer l'heure dans un Isostring (timezone non pris en compte)
        getHours(date){
            return date.substring(11,13)
        },
        // récupérer les minutes dans un Isostring (timezone non pris en compte)
        getMinutes(date){
            return date.substring(14,16)
        },
        // récupérer les minutes dans un Isostring (timezone non pris en compte)
        getTimeHHMM(date){
            return date.substring(11,16)
        },
        // convert HH:MM to number exemple ... 12:00 en 720/
        convertHHMMToNumber(date){
        return this.getHours(date)*60 + this.getMinutes(date)*0.0166667
      },
        // nombre(ex: 0.75) en MM:HH (ex:00:45)
        calculTimes(floatTime){
            var sec = 3600 * floatTime
            var date = new Date(0);
            date.setSeconds(sec);
            var timeString = date.toISOString().substr(11, 5);
            return timeString
        },
         // transformer hh:MM(00:45) en nombre (0.75)
         TimeToFloat(time){
            // cette méthode est à revoir
            function decimalAdjust(type, value, exp) {
            // Si l'exposant vaut undefined ou zero...
            if (typeof exp === 'undefined' || +exp === 0) {
            return Math[type](value);
            }
            value = +value;
            exp = +exp;
            // Si value n'est pas un nombre // ou si l'exposant n'est pas entier
            if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
            return NaN;
            }
            // Décalage
            value = value.toString().split('e');
            value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
            // Re "calage"
            value = value.toString().split('e');
            return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
        }
        // Arrondi décimal
        if (!Math.round10) {
            Math.round10 = function(value, exp) {
            return decimalAdjust('round', value, exp);
            };
        }
        // Arrondi décimal inférieur
        if (!Math.floor10) {
            Math.floor10 = function(value, exp) {
            return decimalAdjust('floor', value, exp);
            };
        }
        // Arrondi décimal supérieur
        if (!Math.ceil10) {
            Math.ceil10 = function(value, exp) {
            return decimalAdjust('ceil', value, exp);
            };
        }
            const h = Number(time.substr(0,2))
            const m = Number(time.substr(3,2)) /60
            let floatTime = h + m
            return Math.ceil10(floatTime, -2)
        },
        //arrondi à 5mn près de l'heure et minute
        roundedToNearestFive(date){
            let date_2 = new Date(date)
            const minutes = 5;
            const ms = 1000 * 60 * minutes;
            // utiliser round si on veut atteindre le nombre prêt
            return new Date(Math.ceil(date_2.getTime() / ms) * ms);
        },
        // Fonction pour arrondir une date à 15 minutes près inférieures
        roundDownToNearest15Minutes(date) {
            const roundedDate = new Date(date);
            const minutes = roundedDate.getMinutes();
            const roundedMinutes = Math.floor(minutes / 15) * 15;
            roundedDate.setMinutes(roundedMinutes);
            roundedDate.setSeconds(0);
            // console.log("date formatée tao anaty roundNearest15mn", roundedDate);
            return roundedDate;
        },

        // transcrire type date en français
        getDate(date){
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
            date = date.toLocaleDateString('fr-FR', options)
            return date
        },
        // transcrire type date en français sans UTC timezone
        dateToFr2(date){
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
            let date2 = new Date(date)
            let date3 = date2.toLocaleDateString('fr-FR', options)
            return date3 + ' à ' + date.slice(11,16)
        },
        // prendre la date la plus lointaine
        getMinDate(a){
           return new Date(Math.min(...a.map(e => new Date(e.MeasureDate))));
        },
        // supprimer tous les espacements et transformer en Int
        dateInt(mydate){
            var stringdate;
            stringdate =  mydate.substr(0, 10)
            stringdate = stringdate.replace(/-/g,'')
            stringdate = stringdate.replace(' ','')
            stringdate = stringdate.replace(':','')
            return Number(stringdate)
        }
    }
}